import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpForApprovalDeleteTransactionConfirmationScheme = ({
  onClose,
  onConfirm,
  isLoading,
  title,
  description,
  closeButtonLabel,
  confirmButtonLabel
}) => {
  return (
    <div className={'scheme-wrapper for-approval-delete-confirmation'}>
      <div className={'scheme-header'}>
        <p className={'title'}>{title}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>

      <div className={'scheme-content'}>
        <p className={'description-text'}>{description}</p>
      </div>

      <div className={'scheme-footer'}>
        <Button
          className={'confirm-button'}
          type={'outline'}
          size={'large'}
          isLoading={isLoading}
          onClick={onConfirm}
          isDisabled={isLoading}
        >
          {confirmButtonLabel}
        </Button>

        <Button
          className={'cancel-button'}
          type={'primary'}
          size={'large'}
          isLoading={isLoading}
          onClick={onClose}
          isDisabled={isLoading}
        >
          {closeButtonLabel}
        </Button>
      </div>
    </div>
  );
};

PopUpForApprovalDeleteTransactionConfirmationScheme.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default PopUpForApprovalDeleteTransactionConfirmationScheme;
