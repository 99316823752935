import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ReactComponent as SuccessIcon } from 'assets/new-succes-icon.svg';
import i18nContext from 'components/i18n-context';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpForApprovalTransactionConfirmationScheme = ({
  onClose,
  onConfirm,
  isLoading,
  isSuccess,
  isForApprovalTransactionEdit = false
}) => {
  const i18n = useContext(i18nContext);

  return (
    <div className='scheme-wrapper for-approval-confirmation'>
      <div className='scheme-header'>
        <p className='title'>
          {i18n.getMessage(isForApprovalTransactionEdit ? 'popUp.editPaymentForLater' : 'popUp.savePaymentForLater')}
        </p>
        <CloseIcon className='scheme-close-icon' onClick={onClose} />
      </div>

      {isSuccess ? (
        <div className='scheme-content-success'>
          <SuccessIcon />
          <p className='primary-text'>
            {i18n.getMessage(isForApprovalTransactionEdit ? 'popUp.editPaymentSuccess' : 'popUp.savePaymentSuccess')}
          </p>
          <p className='secondary-text'>{i18n.getMessage('popUp.storedPaymentLocation')}</p>
        </div>
      ) : (
        <>
          <div className='scheme-content'>
            <p>
              {i18n.getMessage(
                isForApprovalTransactionEdit ? 'popUp.editPaymentDescription' : 'popUp.savePaymentDescription'
              )}
            </p>
            <p className='secondary-text'>{i18n.getMessage('popUp.storedPaymentLocation')}</p>
          </div>

          <div className='scheme-footer'>
            <Button
              className='save-payment-button'
              type='primary'
              size='large'
              isLoading={isLoading}
              onClick={onConfirm}
              isDisabled={isLoading}
            >
              {i18n.getMessage(isForApprovalTransactionEdit ? 'button.editPayment' : 'button.savePayment')}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

PopUpForApprovalTransactionConfirmationScheme.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isForApprovalTransactionEdit: PropTypes.bool
};

export default PopUpForApprovalTransactionConfirmationScheme;
