import { useContext } from 'react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import AmountInput from 'components/common/AmountInput';
import { MAX_UPLOADED_FILES_SIZE } from 'components/common/constants';
import Loader from 'components/common/Loader';
import { getMaxDescriptionSize } from 'components/common/PaymentForm/utils';
import { CRYPTO_CURRENCY_PRECISION } from 'services/currenciesConstant';
import { amountFormattedValue, convertBytesToMegabytes, getErrorMessageForAlert, strToFloat } from 'services/utils';
import { AttachDoc } from 'uikit/AttachDoc/AttachDoc';
import Button from 'uikit/Button/Button';
import Input from 'uikit/Input/Input';

const BottomPartForm = ({
  values,
  errors,
  error,
  handleChange,
  handleSubmit,
  serverFieldsErrors,
  onAmountIBANChange,
  calculateCommission,
  handleCheckTransfer,
  isSubmitDisabled,
  isLoading,
  uploadedFiles,
  uploadDocuments,
  removePaymentFile,
  paymentProvider,
  paymentMethod,
  currency,
  customerNumber,
  currentWalletNumber,
  commission,
  isCryptoForm = false,
  setIsPreparedTransactionsCreation,
  isForApprovalTransactionEdit,
  isUserCanCreatePreparedTransaction
}) => {
  const i18n = useContext(i18nContext);

  const getCommissionValue = () => {
    if (!commission.value && !commission.currency) {
      return '0.00';
    }
    //eslint-disable-next-line max-len
    return `${amountFormattedValue(commission.value, isCryptoForm ? CRYPTO_CURRENCY_PRECISION : 2)} ${commission.currency}`;
  };

  const handleBlurAmountField = async () => {
    await calculateCommission(customerNumber, {
      walletNumber: currentWalletNumber,
      paymentMethod: paymentMethod,
      amount: strToFloat(values?.amount?.toString() || '')
    });
    await handleCheckTransfer('amount');
  };

  return (
    <>
      <div className={'inputs-wrapper-evenly'}>
        <AmountInput
          isRequired={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.amount.label')}
          name={'amount'}
          value={values.amount}
          error={errors.amount || serverFieldsErrors.amount}
          onChange={onAmountIBANChange}
          onBlur={handleBlurAmountField}
          precision={isCryptoForm ? CRYPTO_CURRENCY_PRECISION : 2}
        />
        <Input
          isDisabled={true}
          autoComplete={'off'}
          label={i18n.getMessage('transfer.form.commission.label')}
          value={values.amount && commission.value !== '' ? getCommissionValue() : ''}
          onChange={handleChange}
        />
      </div>
      {!isCryptoForm && (
        <div className={'inputs-wrapper'}>
          <Input
            isRequired={true}
            autoComplete={'off'}
            className={'transfer-details'}
            label={i18n.getMessage('transfer.form.transferDetails.label')}
            name={'comment'}
            value={values.comment}
            error={errors.comment || serverFieldsErrors.comment}
            onChange={handleChange}
            onBlur={() => handleCheckTransfer('comment')}
            max={getMaxDescriptionSize(paymentProvider, paymentMethod, currency)}
          />
        </div>
      )}

      <div className={'attach-doc-wrapper'}>
        {!isCryptoForm && (
          <AttachDoc
            label={i18n.getMessage('transfer.form.attachDoc.label')}
            className={'attach-doc-input hidden'}
            type={'file'}
            size={MAX_UPLOADED_FILES_SIZE}
            files={uploadedFiles}
            onChange={uploadDocuments}
            onRemoveFiles={removePaymentFile}
            error={error?.type === 'attachDoc' && getErrorMessageForAlert(i18n, error)}
            hintText={i18n.getMessage('attachDoc.hint.text', {
              maxFilesSize: convertBytesToMegabytes(MAX_UPLOADED_FILES_SIZE)
            })}
          />
        )}

        {isForApprovalTransactionEdit && (
          <Button
            className={'save-transfer-button'}
            type={'primary'}
            roleType={'button'}
            size={'large'}
            onClick={() => {
              setIsPreparedTransactionsCreation(true);
              handleSubmit();
            }}
            isDisabled={isSubmitDisabled}
          >
            {isLoading ? <Loader /> : i18n.getMessage('sendMoney.button.save')}
          </Button>
        )}
      </div>

      {!isForApprovalTransactionEdit && (
        <div className={'inputs-wrapper-evenly'}>
          <Button
            className={'send-transfer-button'}
            type={'outline'}
            roleType={'button'}
            size={'large'}
            onClick={() => {
              setIsPreparedTransactionsCreation(true);
              handleSubmit();
            }}
            isDisabled={isSubmitDisabled || !isUserCanCreatePreparedTransaction}
          >
            {isLoading ? <Loader /> : i18n.getMessage('sendMoney.button.saveForApproval')}
          </Button>
          <Button
            className={'send-transfer-button'}
            type={'primary'}
            roleType={'submit'}
            size={'large'}
            onClick={handleSubmit}
            isDisabled={isSubmitDisabled}
          >
            {isLoading ? <Loader /> : i18n.getMessage('sendMoney.button.send')}
          </Button>
        </div>
      )}
    </>
  );
};

BottomPartForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  error: PropTypes.any,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  serverFieldsErrors: PropTypes.object,
  onAmountIBANChange: PropTypes.func,
  calculateCommission: PropTypes.func,
  handleCheckTransfer: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadedFiles: PropTypes.array,
  uploadDocuments: PropTypes.func.isRequired,
  removePaymentFile: PropTypes.func,
  paymentProvider: PropTypes.string,
  paymentMethod: PropTypes.string,
  currency: PropTypes.string,
  customerNumber: PropTypes.string,
  currentWalletNumber: PropTypes.string,
  commission: PropTypes.object,
  isCryptoForm: PropTypes.bool,
  setIsPreparedTransactionsCreation: PropTypes.func,
  isForApprovalTransactionEdit: PropTypes.bool,
  isUserCanCreatePreparedTransaction: PropTypes.bool
};

export default BottomPartForm;
