import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import UnapprovedPaymentsTableFilters from './components/UnapprovedPaymentsTableFilters/UnapprovedPaymentsTableFilters';
import UnapprovedPaymentsTable from './components/UnapprovedPaymentsTable';
import MultiPaymentConfirmations from './components/MultiPaymentConfirmations/MultiPaymentConfirmations';
import PaymentConfirmation from './components/PaymentConfirmation/PaymentConfirmation';
import { BackButton } from 'components/common/BackButton/BackButton';
import PaymentForm from 'components/common/PaymentForm';
//eslint-disable-next-line max-len
import PopUpForApprovalDeleteTransactionConfirmationScheme from 'components/common/PopUpScheme/PopUpForApprovalDeleteTransactionConfirmationScheme';
//eslint-disable-next-line max-len
import PopUpForApprovalSubmitTransactionConfirmation from 'components/common/PopUpScheme/PopUpForApprovalSubmitTransactionConfirmation';
import PopUpForApprovalSuccessScheme from 'components/common/PopUpScheme/PopUpForApprovalSuccessScheme';
import i18nContext from 'components/i18n-context';
import { Container } from 'uikit/Container/Container';
import Button from 'uikit/Button/Button';
import { PopUp } from 'uikit/PopUp/PopUp';
import './UnapprovedPayments.scss';
import Loader from '../common/Loader';

const popUpInitialData = {
  isShowPopUp: false,
  popUpType: '',
  title: '',
  description: '',
  confirmButtonLabel: '',
  cancelButtonLabel: '',
  onCancel: () => {},
  onConfirm: () => {}
};

const POP_UP_TYPE = {
  DELETE: 'DELETE',
  APPROVE: 'APPROVE',
  SUCCESSFUL_CONFIRMATION: 'SUCCESSFUL_CONFIRMATION'
};

const PAGE_CONTENT_TYPE = {
  TABLE: 'TABLE',
  APPROVAL: 'APPROVAL',
  EDIT: 'EDIT'
};

const UnapprovedPayments = ({ customerNumber, userAccounts, paymentStore }) => {
  const i18n = useContext(i18nContext);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [popUpData, setPopUpData] = useState(popUpInitialData);
  const [pageContentType, setPageContentType] = useState(PAGE_CONTENT_TYPE.TABLE);

  useEffect(() => {
    if (customerNumber) {
      paymentStore.getPreparedTransactions(customerNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    customerNumber,
    paymentStore.preparedTransactionsFilters.from_date,
    paymentStore.preparedTransactionsFilters.to_date,
    paymentStore.preparedTransactionsFilters.currency,
    paymentStore.preparedTransactionsFilters.wallet_number,
    paymentStore.preparedTransactionsFilters.search_text,
    paymentStore.preparedTransactionsPagination.size,
    paymentStore.preparedTransactionsPagination.page
  ]);

  useEffect(() => {
    if (customerNumber && paymentStore.isPreparedTransactionsSuccessfullyDeleted) {
      paymentStore.getPreparedTransactions(customerNumber);

      paymentStore.setIsPreparedTransactionsSuccessfullyDeleted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNumber, paymentStore.isPreparedTransactionsSuccessfullyDeleted]);

  useEffect(() => {
    if (paymentStore.confirmationId) {
      setPopUpData({
        isShowPopUp: true,
        popUpType: POP_UP_TYPE.APPROVE,
        title: i18n.getMessage('unapprovedPayments.popUp.approveTransaction.title'),
        confirmButtonLabel: i18n.getMessage('unapprovedPayments.popUp.approveTransaction.confirm'),
        onConfirm: async (code) => await paymentStore.confirmPreparedTransactions(customerNumber, code),
        onCancel: () => {
          paymentStore.setConfirmationId(null);
          resetPopUpData();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStore.confirmationId]);

  useEffect(() => {
    if (paymentStore.isPreparedTransactionsSuccessfullyConfirmed) {
      resetPopUpData();

      setPopUpData({
        isShowPopUp: true,
        popUpType: POP_UP_TYPE.SUCCESSFUL_CONFIRMATION,
        title: i18n.getMessage('unapprovedPayments.popUp.successfulConfirmation.title'),
        description: i18n.getMessage('unapprovedPayments.popUp.successfulConfirmation.description'),
        confirmButtonLabel: i18n.getMessage('unapprovedPayments.popUp.successfulConfirmation.confirm'),
        onCancel: async () => {
          await paymentStore.getPreparedTransactions(customerNumber);
          paymentStore.setIsPreparedTransactionsSuccessfullyConfirmed(false);
          resetPopUpData();
          setPageContentType(PAGE_CONTENT_TYPE.TABLE);
          setSelectedTransactions([]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStore.isPreparedTransactionsSuccessfullyConfirmed]);

  const handleEdit = async (transactionId) => {
    await paymentStore.getPreparedTransactionDataForEdit(customerNumber, transactionId, userAccounts);
    setPageContentType(PAGE_CONTENT_TYPE.EDIT);
  };

  const handleSelect = (selectedTransaction, isSelected) => {
    setSelectedTransactions((prev) =>
      isSelected
        ? [...prev, selectedTransaction]
        : prev.filter((transaction) => transaction.id !== selectedTransaction.id)
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelectedTransactions(isSelected ? paymentStore.preparedTransactions.map((t) => t.preparedTransactionId) : []);
  };

  const resetPopUpData = () => {
    setPopUpData(popUpInitialData);
  };

  const handleConfirmTransactionsDeletion = async () => {
    setPopUpData({
      isShowPopUp: true,
      popUpType: POP_UP_TYPE.DELETE,
      title: i18n.getMessage('unapprovedPayments.popUp.deleteTransaction.title'),
      description: i18n.getMessage('unapprovedPayments.popUp.deleteTransaction.description'),
      confirmButtonLabel: i18n.getMessage('unapprovedPayments.popUp.deleteTransaction.confirm'),
      cancelButtonLabel: i18n.getMessage('unapprovedPayments.popUp.deleteTransaction.cancel'),
      onConfirm: async () => {
        const selectedTransactionsIds = selectedTransactions.map((transaction) => transaction.id);

        await paymentStore.deletePreparedTransactions(customerNumber, selectedTransactionsIds);

        resetPopUpData();
      },
      onCancel: resetPopUpData
    });
  };

  const handleSubmitPreparedTransaction = async () => {
    const selectedTransactionsIds = selectedTransactions.map((transaction) => transaction.id);

    await paymentStore.submitPreparedTransactions(customerNumber, selectedTransactionsIds);
  };

  const handleApproveButton = () => {
    setPageContentType(PAGE_CONTENT_TYPE.APPROVAL);
  };

  const handleBackButton = () => {
    if (pageContentType === PAGE_CONTENT_TYPE.EDIT) {
      paymentStore.resetPreparedTransactionData();
    }
    setPageContentType(PAGE_CONTENT_TYPE.TABLE);
    paymentStore.resetPreparedTransactionData();
  };

  const resetPreparedTransactionData = async () => {
    paymentStore.resetPreparedTransactionData();
    await paymentStore.getPreparedTransactions(customerNumber);
    setPageContentType(PAGE_CONTENT_TYPE.TABLE);
  };

  return (
    <section className={'right-section'}>
      {pageContentType !== PAGE_CONTENT_TYPE.TABLE && (
        <BackButton isUnapprovedPayments={true} handleBackButton={handleBackButton} />
      )}

      {pageContentType === PAGE_CONTENT_TYPE.TABLE && (
        <Container className={'unapproved-payments'} header={i18n.getMessage('container.forApproval')}>
          {!paymentStore.isPreparedTransactionsFetched ? (
            <div className={'unapproved-payments-loader'}>
              <Loader />
            </div>
          ) : (
            <>
              <div className={'actions-container'}>
                <div className={'action-buttons'}>
                  <Button
                    type={'outline'}
                    size={'large'}
                    className={'delete-button'}
                    isDisabled={!selectedTransactions.length}
                    onClick={handleConfirmTransactionsDeletion}
                    isLoading={paymentStore.isPreparedTransactionsLoading}
                  >
                    {i18n.getMessage('unapprovedPayments.button.delete')}
                  </Button>
                  <Button
                    type={'primary'}
                    size={'large'}
                    className={'approve-button'}
                    isDisabled={!selectedTransactions.length}
                    onClick={handleApproveButton}
                    isLoading={paymentStore.isPreparedTransactionsLoading}
                  >
                    {i18n.getMessage('unapprovedPayments.button.approve')}
                  </Button>
                </div>
              </div>
              <UnapprovedPaymentsTableFilters
                filters={paymentStore.preparedTransactionsFilters}
                userAccounts={userAccounts}
                onFilterChange={paymentStore.setPreparedTransactionsFilter}
                handleSetDateFilter={paymentStore.setPreparedTransactionsFilter}
                handleSearchText={({ target: { name, value } }) => {
                  paymentStore.setPreparedTransactionsFilter(name, value);
                  paymentStore.getPreparedTransactionsWithDebounce(customerNumber);
                }}
              />
              {paymentStore?.preparedTransactions?.length === 0 ? (
                <div className={'empty-prepared-transactions-wrapper'}>
                  <h2 className={'empty-prepared-transactions-header'}>
                    {i18n.getMessage('unapprovedPayments.emptyTransactions.header')}
                  </h2>
                  <p className={'empty-prepared-transactions-text'}>
                    {i18n.getMessage('unapprovedPayments.emptyTransactions.description')}
                  </p>
                </div>
              ) : (
                <UnapprovedPaymentsTable
                  transactions={paymentStore.preparedTransactions}
                  selectedTransactions={selectedTransactions}
                  onEdit={handleEdit}
                  onSelect={handleSelect}
                  onSelectAll={handleSelectAll}
                  isLoading={paymentStore.isPreparedTransactionsLoading}
                />
              )}
            </>
          )}
        </Container>
      )}

      {pageContentType === PAGE_CONTENT_TYPE.APPROVAL && selectedTransactions.length === 1 && (
        <Container className={'unapproved-payments'} header={i18n.getMessage('container.forApprovalReviewAnConfirm')}>
          <PaymentConfirmation
            transaction={selectedTransactions[0]}
            onEdit={() => {}}
            onConfirm={handleSubmitPreparedTransaction}
            isLoading={paymentStore.isPreparedTransactionsLoading}
            customerNumber={customerNumber}
            setSelectedTransaction={setSelectedTransactions}
          />
        </Container>
      )}

      {pageContentType === PAGE_CONTENT_TYPE.APPROVAL && selectedTransactions.length > 1 && (
        <Container className={'unapproved-payments'} header={i18n.getMessage('container.forApprovalReviewAnConfirm')}>
          <MultiPaymentConfirmations
            transactions={selectedTransactions}
            onConfirm={handleSubmitPreparedTransaction}
            isLoading={paymentStore.isPreparedTransactionsLoading}
            customerNumber={customerNumber}
            setSelectedTransaction={setSelectedTransactions}
          />
        </Container>
      )}

      {pageContentType === PAGE_CONTENT_TYPE.EDIT && (
        <PaymentForm
          customerNumber={customerNumber}
          isForApprovalTransactionEdit={true}
          resetPreparedTransactionData={resetPreparedTransactionData}
        />
      )}

      <PopUp className={'prepared-transactions'} show={popUpData.isShowPopUp} onClose={resetPopUpData}>
        <>
          {popUpData.popUpType === POP_UP_TYPE.DELETE && (
            <PopUpForApprovalDeleteTransactionConfirmationScheme
              title={popUpData.title}
              description={popUpData.description}
              closeButtonLabel={popUpData.cancelButtonLabel}
              confirmButtonLabel={popUpData.confirmButtonLabel}
              onClose={popUpData.onCancel}
              onConfirm={popUpData.onConfirm}
            />
          )}

          {popUpData.popUpType === POP_UP_TYPE.APPROVE && (
            <PopUpForApprovalSubmitTransactionConfirmation
              title={popUpData.title}
              confirmButtonLabel={popUpData.confirmButtonLabel}
              onClose={popUpData.onCancel}
              onConfirm={popUpData.onConfirm}
              clearError={paymentStore.clearConfirmationPopupError}
              error={paymentStore.confirmationPopupError}
              generateSecurityCode={() => {}}
              resendSecurityCode={paymentStore.resendPaymentSecurityCode}
            />
          )}

          {popUpData.popUpType === POP_UP_TYPE.SUCCESSFUL_CONFIRMATION && (
            <PopUpForApprovalSuccessScheme
              title={popUpData.title}
              description={popUpData.description}
              confirmButtonLabel={popUpData.confirmButtonLabel}
              onClose={popUpData.onCancel}
            />
          )}
        </>
      </PopUp>
    </section>
  );
};

UnapprovedPayments.propTypes = {
  customerNumber: PropTypes.string,
  userAccounts: PropTypes.array,
  paymentStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  customerNumber: stores.userStore.userData.account?.account_number,
  userAccounts: stores.userStore.userAccounts,
  paymentStore: stores.paymentStore
}))(observer(UnapprovedPayments));
