import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EditIcon } from 'assets/iconButtons/edit-icon.svg';
import i18nContext from 'components/i18n-context';
import { convertUTCToTransactionsContextTimeZone } from 'components/utils';
import { useMediaQuery } from 'services/useMediaQUery';
import { MEDIA_QUERY } from 'services/utils';
import { IconButton } from 'uikit/IconButton/IconButton';
import { CheckBox } from 'uikit/CheckBox/CheckBox';
import './UnapprovedPaymentsTable.scss';

const UnapprovedPaymentsTable = ({ transactions, selectedTransactions, onEdit, onSelect }) => {
  const i18n = useContext(i18nContext);
  const isMobile = useMediaQuery(MEDIA_QUERY.MOBILE);

  const formatAmount = (amount, currency) => {
    return `${amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })} ${currency}`;
  };

  if (isMobile) {
    return (
      <div className={'unapproved-payments-table'}>
        {transactions.map((transaction, index) => {
          const updatedAt = convertUTCToTransactionsContextTimeZone(transaction.updatedAt);
          const isSelected = selectedTransactions
            .map((selectedTransaction) => selectedTransaction.id)
            .includes(transaction.id);

          return (
            <div className={`unapproved-transaction-item-mobile ${isSelected ? 'selected' : ''}`} key={index}>
              <div className={'unapproved-transaction-item-actions'}>
                <CheckBox
                  isActive={isSelected}
                  showCheckBox={true}
                  onChange={() => onSelect(transaction, !isSelected)}
                />
                <IconButton
                  Icon={EditIcon}
                  onClick={() => onEdit(transaction.id)}
                  tooltipText={i18n.getMessage('unapprovedPayments.table.edit.tooltip')}
                />
              </div>
              <div className={'unapproved-transaction-item-row'}>
                <div className={'unapproved-transaction-item-head-label'}>
                  {i18n.getMessage('unapprovedPayments.table.date')}
                </div>
                <div className={'unapproved-transaction-item-cell-content'}>
                  {`${updatedAt.date} ${updatedAt.time}`}
                </div>
              </div>
              <div className={'unapproved-transaction-item-row'}>
                <div className={'unapproved-transaction-item-head-label'}>
                  {i18n.getMessage('unapprovedPayments.table.payeeName')}
                </div>
                <div className={'unapproved-transaction-item-cell-content'}>{transaction.counterpartyName}</div>
              </div>
              <div className={'unapproved-transaction-item-row'}>
                <div className={'unapproved-transaction-item-head-label'}>
                  {i18n.getMessage('unapprovedPayments.table.purposeOfPayment')}
                </div>
                <div className={'unapproved-transaction-item-cell-content'}>{transaction.comment}</div>
              </div>
              <div className={'unapproved-transaction-item-row'}>
                <div className={'unapproved-transaction-item-head-label'}>
                  {i18n.getMessage('unapprovedPayments.table.amount')}
                </div>
                <div className={'unapproved-transaction-item-cell-content'}>
                  {formatAmount(transaction.amount, transaction.currency)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={'unapproved-payments-table'}>
      <div className={'table-header'}>
        <div className={'checkbox-cell'} />
        <div className={'date-cell'}>{i18n.getMessage('unapprovedPayments.table.date')}</div>
        <div className={'payee-cell'}>{i18n.getMessage('unapprovedPayments.table.payeeName')}</div>
        <div className={'purpose-cell'}>{i18n.getMessage('unapprovedPayments.table.purposeOfPayment')}</div>
        <div className={'amount-cell'}>{i18n.getMessage('unapprovedPayments.table.amount')}</div>
        <div className={'edit-cell'}>{i18n.getMessage('unapprovedPayments.table.edit')}</div>
      </div>
      <div className={'table-body'}>
        {transactions.map((transaction) => {
          const updatedAt = convertUTCToTransactionsContextTimeZone(transaction.updatedAt);
          const isSelected = selectedTransactions
            .map((selectedTransaction) => selectedTransaction.id)
            .includes(transaction.id);

          return (
            <div key={transaction.id} className={`table-row ${isSelected ? 'selected' : ''}`}>
              <div className={'checkbox-cell'}>
                <CheckBox
                  isActive={isSelected}
                  showCheckBox={true}
                  onChange={() => onSelect(transaction, !isSelected)}
                />
              </div>
              <div className={'date-cell'}>{`${updatedAt.date} ${updatedAt.time}`}</div>
              <div className={'payee-cell'}>{transaction.counterpartyName}</div>
              <div className={'purpose-cell'}>{transaction.comment}</div>
              <div className={'amount-cell'}>{formatAmount(transaction.amount, transaction.currency)}</div>
              <div className={'edit-cell'}>
                <IconButton
                  Icon={EditIcon}
                  onClick={() => onEdit(transaction.id)}
                  tooltipText={i18n.getMessage('unapprovedPayments.table.edit.tooltip')}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

UnapprovedPaymentsTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  selectedTransactions: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default UnapprovedPaymentsTable;
