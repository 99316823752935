import { useContext, useMemo, useState, useEffect, Fragment } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import { PAYMENT_METHOD } from 'components/common/constants';
import getCommissionRequestModel from 'components/UnapprovedPayments/models/getCommissionRequestModel';
import { CURRENCIES } from 'services/currenciesConstant';
import { amountFormattedValue, isCryptoProvider } from 'services/utils';
import Button from 'uikit/Button/Button';
import './MultiPaymentConfirmations.scss';

const MultiPaymentConfirmations = ({
  transactions,
  onConfirm,
  isLoading,
  customerNumber,
  setSelectedTransaction,
  paymentStore
}) => {
  const i18n = useContext(i18nContext);

  useEffect(() => {
    if (transactions) {
      const fetchCommission = async () => {
        const requestData = transactions.map((transaction) => getCommissionRequestModel(transaction));

        await paymentStore.getPreparedTransactionsCommissionsInBatch(
          customerNumber,
          requestData,
          transactions,
          setSelectedTransaction
        );
      };

      fetchCommission();
    }
  }, []);

  const groupedByCurrency = useMemo(() => {
    return transactions.reduce((acc, transaction) => {
      const isCrypto = isCryptoProvider(transaction?.transferProvider);
      const currency = transaction.currency;

      if (isCrypto) {
        acc[transaction.id] = {
          amounts: [transaction.amount],
          commissions: [transaction?.commission],
          accounts: new Set([transaction.senderAccount]),
          recipients: new Set([transaction.counterpartyName]),
          paymentMethods: new Set([transaction.paymentMethod]),
          senderName: transaction.senderName,
          currency: transaction.currency,
          isCrypto: true
        };
      } else {
        if (!acc[currency]) {
          acc[currency] = {
            amounts: [],
            commissions: [],
            accounts: new Set(),
            recipients: new Set(),
            paymentMethods: new Set(),
            senderName: transaction.senderName,
            currency: transaction.currency,
            isCrypto: false
          };
        }
        acc[currency].amounts.push(transaction.amount);
        acc[currency].commissions.push(transaction?.commission || 0);
        acc[currency].accounts.add(transaction.senderAccount);
        acc[currency].recipients.add(`${transaction.counterpartyName}`);
        acc[currency].paymentMethods.add(transaction.paymentMethod);
        acc[currency].currency = transaction.currency;
      }
      return acc;
    }, {});
  }, [transactions]);

  const renderDetailRow = (label, value, valueClassName) => (
    <div className='payment-detail-row' key={`${label}-${value}`}>
      <div className={`payment-detail-label`}>{label}</div>
      <div className={`payment-detail-value ${valueClassName}`}>{value}</div>
    </div>
  );

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <div className='payment-confirmation-container'>
      <div className='payment-confirmation-card'>
        <div className='payment-section'>
          <h3 className='section-title'>{i18n.getMessage('unapprovedPayments.paymentConfirmation.paymentDetails')}</h3>
          {Object.entries(groupedByCurrency).map(([key, data, index]) => {
            const currencySymbol = CURRENCIES[data.currency]?.symbol || data.currency;
            return (
              <Fragment key={`${key}-${index}`}>
                {renderDetailRow(
                  i18n.getMessage('unapprovedPayments.paymentConfirmation.amount'),
                  `${currencySymbol} ${amountFormattedValue(
                    data.amounts.reduce((sum, amount) => sum + amount, 0),
                    data.isCrypto ? 8 : 2
                  )}`,
                  'amount-highlight'
                )}
                {renderDetailRow(
                  i18n.getMessage('unapprovedPayments.paymentConfirmation.commission'),
                  `${currencySymbol} ${amountFormattedValue(
                    data.commissions.reduce((sum, commission) => sum + commission, 0),
                    data.isCrypto ? 8 : 2
                  )}`
                )}
              </Fragment>
            );
          })}
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.paymentMethod'),
            Array.from(
              new Set(transactions.map((t) => i18n.getMessage(`transfer.paymentMethod.${t.paymentMethod}`)))
            ).join(', '),
            'payment-method-break-word'
          )}
        </div>

        <div className='section-divider' />

        <div className='payment-section'>
          <h3 className='section-title'>{i18n.getMessage('unapprovedPayments.paymentConfirmation.senderDetails')}</h3>
          {Object.entries(groupedByCurrency).map(([key, data, index]) => (
            <Fragment key={`${key}-${index}`}>
              {Array.from(data.accounts).map((account) =>
                renderDetailRow(
                  data.paymentMethods.has(PAYMENT_METHOD.CRYPTO)
                    ? i18n.getMessage('unapprovedPayments.paymentConfirmation.walletAddress')
                    : i18n.getMessage('unapprovedPayments.paymentConfirmation.account'),
                  account
                )
              )}
            </Fragment>
          ))}
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.name'),
            transactions[0].senderName || 'Unknown Sender'
          )}
        </div>

        <div className='section-divider' />

        <div className='payment-section'>
          <h3 className='section-title'>
            {i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientDetails')}
          </h3>
          {Object.entries(groupedByCurrency).map(([key, data, index]) => (
            <Fragment key={`${key}-${index}`}>
              {Array.from(data.recipients).map((recipient) =>
                renderDetailRow(
                  data.paymentMethods.has(PAYMENT_METHOD.CRYPTO)
                    ? i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientWalletAddress')
                    : i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientName'),
                  recipient
                )
              )}
            </Fragment>
          ))}
        </div>
      </div>

      <div className={'payment-actions'}>
        <Button type={'primary'} onClick={handleConfirm} isLoading={isLoading}>
          {i18n.getMessage('unapprovedPayments.paymentConfirmation.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

MultiPaymentConfirmations.propTypes = {
  transactions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      accountNumber: PropTypes.string,
      walletNumber: PropTypes.string,
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      commission: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
      currency: PropTypes.string,
      paymentMethod: PropTypes.string,
      transferProvider: PropTypes.string,
      senderName: PropTypes.string,
      senderAccount: PropTypes.string,
      counterpartyName: PropTypes.string,
      comment: PropTypes.string,
      transactionData: PropTypes.object,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string
    })
  ),
  onConfirm: PropTypes.func,
  isLoading: PropTypes.bool,
  customerNumber: PropTypes.string,
  paymentStore: MobXPropTypes.observableObject,
  setSelectedTransaction: PropTypes.func
};

export default inject((stores) => ({
  paymentStore: stores.paymentStore
}))(observer(MultiPaymentConfirmations));
