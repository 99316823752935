import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18n';
import {
  MAX_UPLOADED_FILES_SIZE,
  AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
  PAYMENT_PROVIDERS,
  MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE,
  BACKEND_MODULE_TYPES,
  PAGINATION_SIZES
} from 'components/common/constants';
import {
  filteringPaymentMethods,
  sortingPaymentMethods,
  moveSelectedMethodToFront,
  mapIbanCredentials
} from 'components/common/PaymentForm/utils';
import { updateConfirmationActionType } from 'services/authUtils';
import { CRYPTO_CURRENCY_PRECISION } from 'services/currenciesConstant';
import {
  amountFormattedValue,
  convertDateToServerFilter,
  debounce,
  isCryptoProvider,
  replaceSpaces
} from 'services/utils';
import {
  transactionPaymentCheck,
  transactionPaymentCreate,
  uploadDocuments,
  transactionPaymentConfirm,
  resendSecurityCode,
  calculatePaymentCommissionRequest,
  transactionCryptoCheck,
  transactionCryptoCreate,
  transactionCryptoConfirm,
  validateRecipientIbanOrCryptoWalletAddressRequest,
  getPreparedTransactionsList,
  createPreparedTransaction,
  updatePreparedTransaction,
  createPreparedCryptoTransaction,
  updatePreparedCryptoTransaction,
  deletePreparedTransactions,
  submitPreparedTransactions,
  confirmPreparedTransactions,
  calculatePaymentCommissionInBatchRequest,
  getPreparedTransactionDataForEditRequest
} from 'services/requestAgent';

class PaymentStore {
  isLoading = false;
  isCommissionLoading = false;
  isIbanCheckLoading = false;
  isFileUploading = false;
  error = null;
  confirmationPopupError = null;
  currentAccount = null;
  providerData = {};
  paymentMethod = null;
  availablePaymentMethods = [];

  uploadedFiles = [];
  isInternalIban = null;
  commission = {
    value: '',
    currency: '',
    type: ''
  };
  transactionForCreate = null;
  serverTransactionData = null;
  transactionNumber = null;
  isTransactionConfirmation = false;
  isSuccess = false;
  previousTransactionInfo = null;
  isRepeatPayment = false;
  ibanCredentials = null;
  iban = null;
  previousIban = null;
  isNonIban = false;
  confirmationId = null;

  preparedTransactionsFilters = {
    search_text: '',
    from_date: null,
    to_date: null,
    currency: null,
    wallet_number: null
  };

  preparedTransactionsPagination = {
    size: PAGINATION_SIZES[0],
    page: 0
  };

  preparedTransactions = [];
  isPreparedTransactionsFetched = false;
  preparedTransactionsTotalElements = null;
  preparedTransactionsTotalPages = null;
  isPreparedTransactionsLoading = false;
  isPreparedTransactionsCreation = false;
  isPreparedTransactionsSuccessfullyCreated = false;
  isPreparedTransactionsSuccessfullyDeleted = false;
  isPreparedTransactionsSuccessfullyConfirmed = false;
  preparedTransactionIdForEdit = null;
  preparedTransactionsError = null;

  constructor() {
    makeAutoObservable(this);
    this.setPreparedTransactionsFilter = this.setPreparedTransactionsFilter.bind(this);
    this.clearConfirmationPopupError = this.clearConfirmationPopupError.bind(this);
    this.setConfirmationId = this.setConfirmationId.bind(this);
    this.setIsPreparedTransactionsCreation = this.setIsPreparedTransactionsCreation.bind(this);
    this.setIsPreparedTransactionsSuccessfullyCreated = this.setIsPreparedTransactionsSuccessfullyCreated.bind(this);
    this.resetPreparedTransactionData = this.resetPreparedTransactionData.bind(this);
  }

  resetPaymentStore() {
    this.isLoading = false;
    this.isCommissionLoading = false;
    this.isFileUploading = false;
    this.error = null;
    this.providerData = {};
    this.paymentMethod = null;
    this.availablePaymentMethods = [];
    this.uploadedFiles = [];
    this.commission = {
      value: '',
      currency: ''
    };
    this.transactionForCreate = null;
    this.serverTransactionData = null;
    this.transactionNumber = null;
    this.isTransactionConfirmation = false;
    this.isSuccess = false;
    this.previousTransactionInfo = null;
    this.isRepeatPayment = false;
    this.confirmationPopupError = null;
    this.currentAccount = null;
    this.ibanCredentials = null;
    this.iban = null;
    this.previousIban = null;
    this.isNonIban = false;
    this.confirmationId = null;

    this.preparedTransactionsFilters = {
      search_text: '',
      from_date: null,
      to_date: null,
      currency: null,
      wallet_number: null
    };

    this.preparedTransactionsPagination = {
      size: PAGINATION_SIZES[0],
      page: 0
    };

    this.preparedTransactions = [];
    this.isPreparedTransactionsFetched = false;
    this.preparedTransactionsTotalElements = null;
    this.preparedTransactionsTotalPages = null;
    this.isPreparedTransactionsLoading = false;
    this.isPreparedTransactionsCreation = false;
    this.isPreparedTransactionsSuccessfullyCreated = false;
    this.isPreparedTransactionsSuccessfullyDeleted = false;
    this.isPreparedTransactionsSuccessfullyConfirmed = false;
    this.preparedTransactionIdForEdit = null;
    this.preparedTransactionsError = null;
  }

  setIsLoading(status) {
    this.isLoading = status;
    this.error = null;
  }

  setError(error) {
    this.error = error;
  }

  clearConfirmationPopupError() {
    this.confirmationPopupError = null;
  }

  clearIbanCredentials() {
    this.ibanCredentials = null;
    this.iban = null;
    this.previousIban = null;
  }

  setIsCommissionLoading(status) {
    this.isCommissionLoading = status;
    this.error = null;
  }

  setIsFileUploading(status) {
    this.isFileUploading = status;
    this.error = null;
  }

  setIsIbanCheckLoading(status) {
    this.isIbanCheckLoading = status;
    this.error = null;
  }

  setIsTransactionConfirmation(isConfirmation) {
    this.isTransactionConfirmation = isConfirmation;
  }

  setSelectedAccount(account) {
    this.currentAccount = account;
  }

  setPreviousTransactionInfo(transaction) {
    this.previousTransactionInfo = {
      ...transaction,
      amount: amountFormattedValue(
        transaction?.amount,
        isCryptoProvider(transaction?.transfer_type) ? CRYPTO_CURRENCY_PRECISION : 2
      )
    };
    this.iban = transaction?.counterparty?.iban;
  }

  setIsRepeatPaymentStatus(status) {
    this.isRepeatPayment = status;
  }

  setProviderData(providerData) {
    this.providerData = providerData;
  }

  setPaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setAvailablePaymentMethods(methods) {
    this.availablePaymentMethods = sortingPaymentMethods(
      methods,
      this.currentAccount?.currency,
      this.currentAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
        ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
        : BEFORE_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
      this.isNonIban,
      this.currentAccount?.transfer_provider
    );
  }

  setIban(iban) {
    this.iban = iban;
  }

  setIsNonIban(status) {
    this.isNonIban = status;
  }

  resetPaymentMethodInfo() {
    this.setAvailablePaymentMethods(filteringPaymentMethods(this.currentAccount?.payment_methods));
  }

  setAccountInfo(account) {
    this.setSelectedAccount(account);
    this.setAvailablePaymentMethods(filteringPaymentMethods(this.currentAccount?.payment_methods));
    if (this.availablePaymentMethods) {
      this.setPaymentMethod(this.availablePaymentMethods[0]);
    }
    this.setPaymentCommission({
      value: '',
      currency: ''
    });
    this.setError(null);
    this.clearIbanCredentials();
    this.setPreviousTransactionInfo(null);
  }

  setPaymentCommission(commission) {
    this.commission = commission;
  }

  resetSuccess() {
    this.isSuccess = false;
  }

  removePaymentFile(fileId) {
    this.uploadedFiles = this.uploadedFiles.filter((file) => file?.id !== fileId);
  }

  uploadDocuments(customerNumber) {
    return async ({ target: { files } }) => {
      if (!Object.keys(files).length) {
        return null;
      }
      this.setIsFileUploading(true);
      try {
        const selectedFiles = [...files];
        const Data = new FormData();
        selectedFiles.forEach((file) => {
          if (file.size > MAX_UPLOADED_FILES_SIZE) {
            throw { code: 'REQUEST_HAS_BEEN_TERMINATED' };
          } else {
            Data.append('file', file, file?.name);
          }
        });
        const documentArray = await uploadDocuments(customerNumber, Data);

        runInAction(() => {
          this.isFileUploading = false;
          this.uploadedFiles = [
            ...this.uploadedFiles,
            ...documentArray.map((file) => ({ name: file?.name, id: file?.id }))
          ];
        });
      } catch (err) {
        runInAction(() => {
          this.isFileUploading = false;
          this.error = { type: 'attachDoc', ...err };
        });
      }
    };
  }

  async calculatePaymentCommission(customerNumber, data) {
    this.setIsCommissionLoading(true);
    try {
      const { commission } = await calculatePaymentCommissionRequest(customerNumber, data);

      runInAction(() => {
        this.commission = {
          value: commission,
          currency: this.currentAccount?.currency
        };
        this.isCommissionLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isCommissionLoading = false;
      });
    }
  }

  async checkTransfer(customerNumber, data) {
    this.setIsLoading(true);
    try {
      const transactionData = await transactionPaymentCheck(customerNumber, data);

      runInAction(() => {
        if (!this.isRepeatPayment) {
          this.paymentMethod = transactionData.payment_method;
        }

        if (this.isRepeatPayment) {
          this.isRepeatPayment = false;
        }
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        if (err.code === 'WALLET_NOT_FOUND') {
          this.error = { code: err.code, message: i18n.getMessage('error.WALLET_NOT_FOUND') };
        }
        this.error = err;
        this.isLoading = false;
      });
    }
  }

  async validateIban(customerNumber, senderAccountNumber, iban) {
    if (iban !== this.previousIban) {
      this.setIsIbanCheckLoading(true);
      this.setIban(iban);
      this.ibanCredentials = null;
      try {
        const ibanCredentials = mapIbanCredentials(
          await validateRecipientIbanOrCryptoWalletAddressRequest(customerNumber, {
            senderWalletNumber: senderAccountNumber,
            recipientIban: replaceSpaces(iban)
          })
        );

        const sortedPaymentMethods = sortingPaymentMethods(
          filteringPaymentMethods(ibanCredentials?.payment_methods),
          this.currentAccount?.currency,
          this.currentAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
            ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
            : AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
          this.isNonIban,
          this.currentAccount?.transfer_provider
        );

        runInAction(() => {
          this.isInternalIban = true;
          this.ibanCredentials = ibanCredentials;

          if (this.isRepeatPayment) {
            this.availablePaymentMethods = moveSelectedMethodToFront(sortedPaymentMethods, this.paymentMethod);
            this.isRepeatPayment = false;
          } else {
            this.availablePaymentMethods = sortedPaymentMethods;
            this.paymentMethod = this.availablePaymentMethods[0];
          }
          if (ibanCredentials?.payment_methods.length === 0) {
            this.error = { fields: [{ field: 'iban', code: 'NO_PAYMENT_METHODS_AVAILABLE' }] };
          }
        });
      } catch (err) {
        runInAction(() => {
          if (err?.code && err.code === 'IBAN_CHECK_FAILED') {
            this.error = { fields: [{ field: 'iban', code: 'EXTERNAL_IBAN_VALIDATION_FAILED' }] };
          } else {
            this.error = err;
          }
        });
      } finally {
        runInAction(() => {
          this.previousIban = iban;
          this.isIbanCheckLoading = false;
        });
      }
    }
  }

  async validateTransfer({ customerNumber, providerData, model }) {
    this.setIsLoading(true);
    const data = model({
      providerTableData: providerData,
      data: {
        wallet: this.currentAccount,
        paymentMethod: this.paymentMethod,
        uploadedFiles: this.uploadedFiles.map((file) => file.id),
        ...this.ibanCredentials
      }
    });

    try {
      const transactionData = await transactionPaymentCheck(customerNumber, data);

      runInAction(() => {
        this.isLoading = false;
        if (!this.isPreparedTransactionsCreation) {
          this.isTransactionConfirmation = true;
        }
        this.paymentMethod = transactionData.payment_method;
        this.transactionForCreate = {
          ...data,
          source_amount: transactionData.source_amount,
          target_amount: transactionData.target_amount
        };
        this.serverTransactionData = transactionData;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  createTransfer(customerNumber) {
    return async () => {
      this.setIsLoading(true);
      try {
        const { confirmation_id: confirmationId, confirmation_type: confirmationType } = await transactionPaymentCreate(
          customerNumber,
          {
            ...this.transactionForCreate
          }
        );

        updateConfirmationActionType(confirmationType);

        runInAction(() => {
          this.isLoading = false;
          this.confirmationId = confirmationId;
        });
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.confirmationPopupError = err;
        });
      }
    };
  }

  async verifyPaymentSecurityCode(customerNumber, securityCode) {
    try {
      this.setIsLoading(true);
      await transactionPaymentConfirm(customerNumber, {
        code: securityCode,
        confirmation_id: this.confirmationId
      });

      runInAction(() => {
        this.isLoading = false;
        this.transactionNumber = null;
        this.isTransactionConfirmation = false;
        this.isSuccess = true;
        this.uploadedFiles = [];
        this.previousTransactionInfo = null;
        this.ibanCredentials = null;
        this.iban = null;
        this.previousIban = null;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.confirmationPopupError = err;
      });
    }
  }

  async resendPaymentSecurityCode() {
    try {
      await resendSecurityCode(BACKEND_MODULE_TYPES.PAYMENT, this.confirmationId);
    } catch (err) {
      runInAction(() => {
        this.confirmationPopupError = err;
      });
    }
  }

  async validateRecipientCryptoWalletAddress(customerNumber, data) {
    this.setIsLoading(true);
    try {
      const { paymentMethods } = await validateRecipientIbanOrCryptoWalletAddressRequest(customerNumber, data);

      const sortedPaymentMethods = sortingPaymentMethods(
        filteringPaymentMethods(paymentMethods),
        this.currentAccount?.currency,
        this.currentAccount?.transfer_provider === PAYMENT_PROVIDERS.MANUAL
          ? MANUAL_PROVIDER_PAYMENT_METHODS_SORTING_EXAMPLE
          : AFTER_VALIDATE_PAYMENT_METHODS_SORTING_EXAMPLE,
        undefined,
        this.currentAccount?.transfer_provider
      );

      runInAction(() => {
        this.isInternalIban = true;

        if (this.isRepeatPayment) {
          this.availablePaymentMethods = moveSelectedMethodToFront(sortedPaymentMethods, this.paymentMethod);
          this.isRepeatPayment = false;
        } else {
          this.availablePaymentMethods = sortedPaymentMethods;
          this.paymentMethod = this.availablePaymentMethods[0];
        }
        if (paymentMethods.length === 0) {
          this.error = { fields: [{ field: 'recipientWallet', code: 'NO_PAYMENT_METHODS_AVAILABLE' }] };
        }
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async checkCryptoTransfer(customerNumber, data) {
    this.setIsLoading(true);
    try {
      const transactionData = await transactionCryptoCheck(customerNumber, data);

      runInAction(() => {
        if (!this.isRepeatPayment) {
          this.paymentMethod = transactionData.payment_method;
        }

        if (this.isRepeatPayment) {
          this.isRepeatPayment = false;
        }
        this.isLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        if (err.code === 'WALLET_NOT_FOUND') {
          this.error = { code: err.code, message: i18n.getMessage('error.WALLET_NOT_FOUND') };
        }
        this.error = err;
        this.isLoading = false;
      });
    }
  }

  async validateCryptoTransfer({ customerNumber, providerData, model }) {
    this.setIsLoading(true);
    const data = model({
      providerTableData: providerData,
      data: {
        wallet: this.currentAccount,
        paymentMethod: this.paymentMethod,
        uploadedFiles: this.uploadedFiles.map((file) => file.id),
        ...this.ibanCredentials
      }
    });

    try {
      const transactionData = await transactionCryptoCheck(customerNumber, data);

      runInAction(() => {
        this.isLoading = false;
        if (!this.isPreparedTransactionsCreation) {
          this.isTransactionConfirmation = true;
        }
        this.paymentMethod = transactionData.payment_method;
        this.transactionForCreate = {
          ...data,
          source_amount: transactionData.source_amount,
          target_amount: transactionData.target_amount
        };
        this.serverTransactionData = transactionData;
        this.commission = {
          value: transactionData.total_commissions,
          currency: transactionData.currency_code,
          type: transactionData.commission_type
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.error = err;
      });
    }
  }

  createCryptoTransfer(customerNumber) {
    return async () => {
      this.setIsLoading(true);
      try {
        const { confirmation_id: confirmationId, confirmation_type: confirmationType } = await transactionCryptoCreate(
          customerNumber,
          {
            ...this.transactionForCreate
          }
        );

        updateConfirmationActionType(confirmationType);

        runInAction(() => {
          this.isLoading = false;
          this.confirmationId = confirmationId;
        });
      } catch (err) {
        runInAction(() => {
          this.isLoading = false;
          this.confirmationPopupError = err;
        });
      }
    };
  }

  async verifyCryptoSecurityCode(customerNumber, securityCode) {
    try {
      this.setIsLoading(true);
      await transactionCryptoConfirm(customerNumber, {
        code: securityCode,
        confirmation_id: this.confirmationId
      });

      runInAction(() => {
        this.isLoading = false;
        this.transactionNumber = null;
        this.isTransactionConfirmation = false;
        this.isSuccess = true;
        this.uploadedFiles = [];
        this.previousTransactionInfo = null;
        this.ibanCredentials = null;
        this.iban = null;
        this.previousIban = null;
      });
    } catch (err) {
      runInAction(() => {
        this.isLoading = false;
        this.confirmationPopupError = err;
      });
    }
  }

  setIsPreparedTransactionsLoading(status) {
    this.isPreparedTransactionsLoading = status;
    this.preparedTransactionsError = null;
  }

  setIsPreparedTransactionsCreation(status) {
    this.isPreparedTransactionsCreation = status;
    this.preparedTransactionsError = null;
  }

  setIsPreparedTransactionsSuccessfullyCreated(status) {
    this.isPreparedTransactionsSuccessfullyCreated = status;
    this.preparedTransactionsError = null;
  }
  setIsPreparedTransactionsSuccessfullyDeleted(status) {
    this.isPreparedTransactionsSuccessfullyDeleted = status;
    this.preparedTransactionsError = null;
  }
  setIsPreparedTransactionsSuccessfullyConfirmed(status) {
    this.isPreparedTransactionsSuccessfullyConfirmed = status;
    this.preparedTransactionsError = null;
  }
  setConfirmationId(confirmationId) {
    this.confirmationId = confirmationId;
  }

  setPreparedTransactionsFilter(fieldName, value) {
    this.preparedTransactionsFilters[fieldName] = Array.isArray(value) && value.length === 0 ? null : value;
    this.preparedTransactionsPagination.page = 0;
  }

  resetPreparedTransactionData() {
    this.currentAccount = null;
    this.previousTransactionInfo = null;
    this.iban = null;
    this.isRepeatPayment = false;
    this.paymentMethod = null;
    this.availablePaymentMethods = [];
    this.isPreparedTransactionsCreation = false;
    this.isPreparedTransactionsSuccessfullyCreated = false;
    this.isPreparedTransactionsSuccessfullyDeleted = false;
    this.isPreparedTransactionsSuccessfullyConfirmed = false;
    this.preparedTransactionIdForEdit = null;
    this.preparedTransactionsError = null;
  }

  async createPreparedTransaction(customerNumber, isCrypto = false) {
    try {
      this.setIsLoading(true);

      const data = {
        ...this.transactionForCreate
      };

      if (isCrypto) {
        await createPreparedCryptoTransaction(customerNumber, data);
      } else {
        await createPreparedTransaction(customerNumber, data);
      }

      runInAction(() => {
        this.isPreparedTransactionsSuccessfullyCreated = true;
        this.isPreparedTransactionsCreation = false;
        this.preparedTransactionsError = null;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async updatePreparedTransaction(customerNumber, data, isCrypto = false) {
    try {
      this.setIsPreparedTransactionsLoading(true);

      const data = {
        ...this.transactionForCreate
      };

      if (isCrypto) {
        await updatePreparedCryptoTransaction(customerNumber, this.preparedTransactionIdForEdit, data);
      } else {
        await updatePreparedTransaction(customerNumber, this.preparedTransactionIdForEdit, data);
      }

      runInAction(() => {
        this.isPreparedTransactionsSuccessfullyCreated = true;
        this.isPreparedTransactionsCreation = false;
        this.preparedTransactionsError = null;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  async deletePreparedTransactions(customerNumber, preparedTransactionIds) {
    try {
      this.setIsPreparedTransactionsLoading(true);

      await deletePreparedTransactions(customerNumber, preparedTransactionIds);

      runInAction(() => {
        this.isPreparedTransactionsSuccessfullyDeleted = true;
        this.isPreparedTransactionsLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
        this.isPreparedTransactionsLoading = false;
      });
      throw err;
    }
  }

  async submitPreparedTransactions(customerNumber, preparedTransactionIds) {
    try {
      this.setIsPreparedTransactionsLoading(true);
      const { confirmation_id: confirmationId, confirmation_type: confirmationType } = await submitPreparedTransactions(
        customerNumber,
        preparedTransactionIds
      );

      updateConfirmationActionType(confirmationType);

      runInAction(() => {
        this.confirmationId = confirmationId;
        this.preparedTransactionsError = false;
        this.isPreparedTransactionsLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
        this.isPreparedTransactionsLoading = false;
      });
      throw err;
    }
  }

  async confirmPreparedTransactions(customerNumber, code) {
    try {
      this.setIsPreparedTransactionsLoading(true);

      await confirmPreparedTransactions(customerNumber, {
        confirmation_id: this.confirmationId,
        code
      });

      runInAction(() => {
        this.confirmationId = null;
        this.isPreparedTransactionsSuccessfullyConfirmed = true;
        this.preparedTransactionsError = false;
        this.isPreparedTransactionsLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
        this.isPreparedTransactionsLoading = false;
      });
      throw err;
    }
  }

  getPreparedTransactionsWithDebounce = debounce(this.getPreparedTransactions, 400);

  async getPreparedTransactions(customerNumber) {
    try {
      this.setIsPreparedTransactionsLoading(true);
      const query = {
        search_text: this.preparedTransactionsFilters.search_text,
        from_date: convertDateToServerFilter(this.preparedTransactionsFilters.from_date),
        to_date: convertDateToServerFilter(this.preparedTransactionsFilters.to_date),
        currency: this.preparedTransactionsFilters.currency,
        wallet_number: this.preparedTransactionsFilters.wallet_number,
        page: this.preparedTransactionsPagination.page,
        size: this.preparedTransactionsPagination.size
      };

      const { content, total_elements, total_pages, number, size } = await getPreparedTransactionsList(
        customerNumber,
        query
      );

      const parsedContent = content.map((transaction) => {
        try {
          const parsedTransactionData = JSON.parse(transaction.transactionData);
          return {
            ...transaction,
            transactionData: parsedTransactionData
          };
        } catch (error) {
          console.error(`Error parsing transactionData for transaction ${transaction.id}:`, error);
          return {
            ...transaction,
            transactionData: {}
          };
        }
      });

      runInAction(() => {
        this.preparedTransactions = parsedContent;
        this.preparedTransactionsTotalElements = total_elements;
        this.preparedTransactionsTotalPages = total_pages;
        this.preparedTransactionsPagination.size = size;
        this.preparedTransactionsPagination.page = number;
        this.isPreparedTransactionsLoading = false;
        this.isPreparedTransactionsFetched = true;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
        this.isPreparedTransactionsLoading = false;
      });
      throw err;
    }
  }

  async getPreparedTransactionCommission(customerNumber, data, preparedTransactionId, setSelectedTransaction) {
    this.setIsCommissionLoading(true);
    try {
      const { commission } = await calculatePaymentCommissionRequest(customerNumber, data);

      const updatedPreparedTransactions = this.preparedTransactions.map((transaction) => {
        if (transaction.id === preparedTransactionId) {
          const updatedTransaction = {
            ...transaction,
            commission
          };

          setSelectedTransaction([updatedTransaction]);

          return updatedTransaction;
        } else {
          return transaction;
        }
      });

      runInAction(() => {
        this.preparedTransactions = updatedPreparedTransactions;
        this.isCommissionLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isCommissionLoading = false;
      });
    }
  }

  async getPreparedTransactionsCommissionsInBatch(customerNumber, data, preparedTransactions, setSelectedTransaction) {
    this.setIsPreparedTransactionsLoading(true);
    try {
      const commissions = await calculatePaymentCommissionInBatchRequest(customerNumber, data);

      const updatedPreparedTransactions = preparedTransactions.map((transaction, index) => {
        return {
          ...transaction,
          commission: commissions[index]?.commission
        };
      });

      setSelectedTransaction(updatedPreparedTransactions);

      runInAction(() => {
        this.isPreparedTransactionsLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = err;
        this.isPreparedTransactionsLoading = false;
      });
    }
  }

  async getPreparedTransactionDataForEdit(customerNumber, preparedTransactionId, userAccounts) {
    try {
      this.setIsPreparedTransactionsLoading(true);
      const transactionData = await getPreparedTransactionDataForEditRequest(customerNumber, preparedTransactionId);

      const senderAccount = userAccounts.find(
        (userAccount) =>
          userAccount.wallet_number === transactionData?.wallet_number ||
          userAccount.wallet_number === transactionData?.sender_wallet_number
      );

      this.setSelectedAccount(senderAccount);
      this.setPreviousTransactionInfo(transactionData);
      this.setIsRepeatPaymentStatus(true);
      this.setPaymentMethod(transactionData.payment_method);
      this.setAvailablePaymentMethods(filteringPaymentMethods(senderAccount?.payment_methods));

      runInAction(() => {
        this.preparedTransactionIdForEdit = preparedTransactionId;
        this.preparedTransactionsError = null;
        this.isPreparedTransactionsLoading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.preparedTransactionsError = err;
        this.isPreparedTransactionsLoading = false;
      });
      throw err;
    }
  }
}

export default new PaymentStore();
