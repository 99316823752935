import i18n from 'i18n';
import { ICON_BUTTONS_TYPE } from './constants';
// import { isMobile } from '../../services/utils';
import { ReactComponent as MenuIcon } from 'assets/iconButtons/menu-icon.svg';
import { ReactComponent as MenuIconMobile } from 'assets/iconButtons/menu-mobile-icon.svg';
import { ReactComponent as MessagesIcon } from 'assets/iconButtons/messages.svg';
import { ReactComponent as SettingIcon } from 'assets/iconButtons/settings.svg';
import { ReactComponent as ProfileIcon } from 'assets/iconButtons/profile.svg';
import { ReactComponent as LogoutIcon } from 'assets/iconButtons/logout.svg';
import { ReactComponent as VerificationIcon } from 'assets/iconButtons/verification.svg';
import { ReactComponent as DashBoardIcon } from 'assets/iconButtons/dashboard.svg';
import { ReactComponent as NewPaymentIcon } from 'assets/iconButtons/new-payment.svg';
import { ReactComponent as CurrencyExchangeIcon } from 'assets/iconButtons/currency-exchange.svg';
import { ReactComponent as AccountsIcon } from 'assets/iconButtons/accounts.svg';
import { ReactComponent as BetweenAccountsIcon } from 'assets/iconButtons/between-accounts.svg';
import { ReactComponent as CardsIcon } from 'assets/iconButtons/cards.svg';
import { ReactComponent as TransactionsIcon } from 'assets/iconButtons/transactions.svg';
import { ReactComponent as FeesIcon } from 'assets/iconButtons/fees.svg';
import { ReactComponent as CryptoIcon } from 'assets/iconButtons/crypto.svg';
// import { ReactComponent as TemplatesIcon } from 'assets/iconButtons/templates.svg';
// import { ReactComponent as InvoicesIcon } from 'assets/iconButtons/invoices.svg';
import { ReactComponent as RepresentativesIcon } from 'assets/representatives.svg';
import { ReactComponent as UnapprovedPaymentsIcon } from 'assets/iconButtons/unapprovedPayments.svg';
import { ROUTE_PATHS } from '../../routes/constants';
import userStore from '../../stores/userStore';
//import { ReactComponent as  } from 'assets/iconButtons/';

export const getAppRouteButtons = (
  isCustomerExist = true,
  isCustomerVerified = true,
  isAccountsExist = true,
  isMultipleCurrency = true,
  isCryptoAccountsExist = false,
  isFees = false
) => [
  {
    id: 0,
    icon: MenuIcon,
    mobileIcon: MenuIconMobile,
    text: i18n.getMessage('navigation.menu.text'),
    onClick: () => {},
    isDisabled: !isCustomerVerified,
    isDisplay: isCustomerVerified
  },
  {
    id: 1,
    type: ICON_BUTTONS_TYPE.NAVIGATION,
    icon: MessagesIcon,
    text: i18n.getMessage('navigation.messages.text'),
    path: ROUTE_PATHS.MESSAGES,
    isDisabled: !isCustomerExist,
    isDisplay: isCustomerExist
  },
  {
    id: 2,
    type: ICON_BUTTONS_TYPE.NAVIGATION,
    icon: SettingIcon,
    text: i18n.getMessage('navigation.settings.text'),
    path: ROUTE_PATHS.SETTINGS,
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 4,
    type: ICON_BUTTONS_TYPE.NAVIGATION,
    icon: LogoutIcon,
    text: i18n.getMessage('navigation.logOut.text'),
    isDisabled: false,
    isDisplay: true
  },
  {
    id: 15,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: VerificationIcon,
    text: i18n.getMessage('menu.verification.text'),
    path: ROUTE_PATHS.VERIFICATION,
    isDisabled: isCustomerVerified,
    isDisplay: !isCustomerVerified || !isCustomerExist
  },
  {
    id: 5,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: DashBoardIcon,
    text: i18n.getMessage('menu.dashboard.text'),
    path: ROUTE_PATHS.DASHBOARD,
    isDisabled: !isCustomerVerified,
    isDisplay: isCustomerVerified && isCustomerExist
  },
  {
    id: 6,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: NewPaymentIcon,
    text: i18n.getMessage('menu.newPayment.text'),
    path: ROUTE_PATHS.NEW_PAYMENT,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 18,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: UnapprovedPaymentsIcon,
    text: i18n.getMessage('menu.unapprovedPayments.text'),
    path: ROUTE_PATHS.UNAPPROVED_PAYMENTS,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 14,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: BetweenAccountsIcon,
    text: i18n.getMessage('menu.betweenAccounts.text'),
    path: ROUTE_PATHS.BETWEEN_ACCOUNTS,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 9,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CardsIcon,
    text: i18n.getMessage('menu.cards.text'),
    path: ROUTE_PATHS.CARDS,
    isDisabled: !isCustomerVerified || !isAccountsExist || !userStore.isCardsEnabled(),
    isDisplay: isCustomerVerified && isAccountsExist && userStore.isCardsEnabled()
  },
  {
    id: 7,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CurrencyExchangeIcon,
    text: i18n.getMessage('menu.currencyExchange.text'),
    path: ROUTE_PATHS.CURRENCY_EXCHANGE,
    isDisabled: !isCustomerVerified || !isAccountsExist || !isMultipleCurrency,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 8,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: AccountsIcon,
    text: i18n.getMessage('menu.accounts.text'),
    path: ROUTE_PATHS.ACCOUNTS,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 10,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: TransactionsIcon,
    text: i18n.getMessage('menu.transactions.text'),
    path: ROUTE_PATHS.TRANSACTIONS,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  {
    id: 3,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: ProfileIcon,
    text: i18n.getMessage('navigation.profile.text'),
    path: ROUTE_PATHS.PROFILE,
    isDisabled: !isCustomerExist,
    isDisplay: isCustomerExist
  },
  // {
  //   id: 11,
  //   type: ICON_BUTTONS_TYPE.MENU,
  //   icon:  InvoicesIcon,
  //   text: i18n.getMessage('menu.invoices.text'),
  //   path: ROUTE_PATHS.INVOICES
  // }
  {
    id: 12,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: RepresentativesIcon,
    text: i18n.getMessage('container.representatives'),
    path: ROUTE_PATHS.REPRESENTATIVES,
    isDisabled: !isCustomerVerified || !isAccountsExist || !userStore.isRepresentativesEnabled(),
    isDisplay: isCustomerVerified && isAccountsExist && userStore.isRepresentativesEnabled()
  },
  isFees && {
    id: 13,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: FeesIcon,
    text: i18n.getMessage('menu.fees.text'),
    path: ROUTE_PATHS.FEES,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isAccountsExist
  },
  isFees && {
    id: 17,
    type: ICON_BUTTONS_TYPE.MENU,
    icon: CryptoIcon,
    text: i18n.getMessage('menu.crypto.text'),
    path: ROUTE_PATHS.CRYPTO,
    isDisabled: !isCustomerVerified || !isAccountsExist,
    isDisplay: isCustomerVerified && isCryptoAccountsExist
  }
  /* Waiting for backend part */
  // isFees && {
  //   id: 16,
  //   type: ICON_BUTTONS_TYPE.MENU,
  //   icon: TemplatesIcon,
  //   text: i18n.getMessage('menu.templates.text'),
  //   path: ROUTE_PATHS.TEMPLATES,
  //   isDisabled: !isCustomerVerified || !isAccountsExist,
  //   isDisplay: isCustomerVerified && isAccountsExist
  // }
  /* Waiting for backend part */
];

export const getHeaderButtons = (isCustomerExist) =>
  getAppRouteButtons(isCustomerExist).filter(
    (button) => button && button.type === ICON_BUTTONS_TYPE.NAVIGATION && button.isDisplay
  );
export const getNavigationButtons = (
  isCustomerExist,
  isCustomerVerified,
  isAccountsExist,
  isMultipleCurrency,
  isCryptoAccountsExist,
  isFees
) =>
  getAppRouteButtons(
    isCustomerExist,
    isCustomerVerified,
    isAccountsExist,
    isMultipleCurrency,
    isCryptoAccountsExist,
    isFees
  ).filter((button) => button && button.type === ICON_BUTTONS_TYPE.MENU && button.isDisplay);
