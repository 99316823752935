import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpForApprovalSuccessScheme = ({ onClose, isLoading, title, description, confirmButtonLabel }) => {
  return (
    <div className={'scheme-wrapper for-approval-success'}>
      <div className={'scheme-header'}>
        <p className={'title'}>{title}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>

      <div className={'scheme-content'}>
        <p className={'description-text'}>{description}</p>
      </div>

      <div className={'scheme-footer'}>
        <Button
          className={'ok-button'}
          type={'primary'}
          size={'large'}
          isLoading={isLoading}
          onClick={onClose}
          isDisabled={isLoading}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};

PopUpForApprovalSuccessScheme.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default PopUpForApprovalSuccessScheme;
