import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PreparedTransactionDateFilter } from './components/PreparedTransactionDateFilter';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import i18nContext from 'components/i18n-context';
import { getAccountTitleWithFormatting, getFirstCreatedAccountDate } from 'components/utils';
import Input from 'uikit/Input/Input';
import { InputDropDown } from 'uikit/InputDropDown/InputDropDown';
import './UnapprovedPaymentsTableFilters.scss';

const UnapprovedPaymentsTableFilters = ({
  filters,
  userAccounts,
  onFilterChange,
  handleSetDateFilter,
  handleSearchText
}) => {
  const i18n = useContext(i18nContext);
  const [firstCreatedAccountDate, setFirstCreatedAccountDate] = useState(null);
  const todayDate = new Date();

  useEffect(() => {
    if (userAccounts && userAccounts.length > 0) {
      setFirstCreatedAccountDate(getFirstCreatedAccountDate(userAccounts));
    }
  }, [userAccounts]);

  const walletsOptions = userAccounts?.map((wallet) => {
    const title = getAccountTitleWithFormatting(wallet);

    return {
      key: wallet?.wallet_number,
      value: title,
      currency: wallet?.currency,
      networkCode: wallet?.network
    };
  });

  const currencyOptions = [...new Set(userAccounts?.map(({ currency }) => currency))].map((currency) => {
    return {
      key: currency,
      value: currency
    };
  });

  return (
    <div className={'unapproved-payments-filters'}>
      <div className={'filter-group'}>
        <PreparedTransactionDateFilter
          name={'from_date'}
          value={filters.from_date}
          onChange={handleSetDateFilter}
          label={i18n.getMessage('unapprovedPayments.filters.from')}
          minDate={firstCreatedAccountDate}
          maxDate={todayDate}
        />
        <InputDropDown
          className={'unapproved-payments-filter-input currency'}
          name={'currency'}
          value={filters.currency}
          options={currencyOptions}
          onChange={onFilterChange}
          placeholder={i18n.getMessage('unapprovedPayments.filters.currency.placeholder')}
          isMulti
        />
        <InputDropDown
          className={'unapproved-payments-filter-input wallet'}
          name={'wallet_number'}
          options={walletsOptions}
          value={filters.wallet_number}
          onChange={onFilterChange}
          placeholder={i18n.getMessage('unapprovedPayments.filters.wallet.placeholder')}
          isMulti
        />
        <Input
          className={'unapproved-payments-filter-input search-text-input'}
          name={'search_text'}
          value={filters.search_text}
          onChange={handleSearchText}
          placeholder={i18n.getMessage('unapprovedPayments.filters.search.placeholder')}
          Icon={SearchIcon}
        />
      </div>
    </div>
  );
};

UnapprovedPaymentsTableFilters.propTypes = {
  filters: PropTypes.object,
  userAccounts: PropTypes.array,
  onFilterChange: PropTypes.func,
  handleSetDateFilter: PropTypes.func,
  handleSearchText: PropTypes.func
};

export default UnapprovedPaymentsTableFilters;
