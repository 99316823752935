import { useContext, useEffect } from 'react';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import i18nContext from 'components/i18n-context';
import getCommissionRequestModel from 'components/UnapprovedPayments/models/getCommissionRequestModel';
import { CURRENCIES } from 'services/currenciesConstant';
import { amountFormattedValue, isCryptoProvider } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PaymentConfirmation.scss';

const PaymentConfirmation = ({
  transaction,
  onEdit,
  onConfirm,
  isLoading,
  customerNumber,
  setSelectedTransaction,
  paymentStore
}) => {
  const i18n = useContext(i18nContext);
  const isCrypto = isCryptoProvider(transaction?.transferProvider);

  useEffect(() => {
    if (transaction) {
      const fetchCommission = async () => {
        const requestData = getCommissionRequestModel(transaction);

        await paymentStore.getPreparedTransactionCommission(
          customerNumber,
          requestData,
          transaction.id,
          setSelectedTransaction
        );
      };

      fetchCommission();
    }
  }, []);

  const handleEdit = () => {
    if (onEdit) {
      onEdit();
    }
  };

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  };

  const renderDetailRow = (label, value, valueClassName) => {
    if (!value) {
      return null;
    }

    return (
      <div className='payment-detail-row'>
        <div className='payment-detail-label'>{label}</div>
        <div className={`payment-detail-value ${valueClassName}`}>{value}</div>
      </div>
    );
  };

  return (
    <div className='payment-confirmation-container'>
      <div className='payment-confirmation-card'>
        <div className='payment-section'>
          <h3 className='section-title'>{i18n.getMessage('unapprovedPayments.paymentConfirmation.paymentDetails')}</h3>
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.amount'),
            `${CURRENCIES[transaction.currency]?.symbol || transaction.currency} ${amountFormattedValue(transaction.amount, isCrypto ? 8 : 2)}`,
            'amount-highlight'
          )}
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.commission'),
            `${CURRENCIES[transaction.currency]?.symbol || transaction.currency} ${amountFormattedValue(transaction.commission, isCrypto ? 8 : 2)}`
          )}
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.paymentMethod'),
            i18n.getMessage(`transfer.paymentMethod.${transaction.paymentMethod}`)
          )}
          {renderDetailRow(
            i18n.getMessage('unapprovedPayments.paymentConfirmation.transferDetails'),
            transaction.comment
          )}
        </div>

        <div className='section-divider' />

        <div className='payment-section'>
          <h3 className='section-title'>{i18n.getMessage('unapprovedPayments.paymentConfirmation.senderDetails')}</h3>
          {isCrypto
            ? renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.walletAddress'),
                transaction.senderAccount
              )
            : renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.account'),
                transaction.senderAccount
              )}
          {isCrypto &&
            renderDetailRow(
              i18n.getMessage('unapprovedPayments.paymentConfirmation.walletId'),
              transaction.walletNumber
            )}
          {renderDetailRow(i18n.getMessage('unapprovedPayments.paymentConfirmation.name'), transaction.senderName)}
        </div>

        <div className='section-divider' />

        <div className='payment-section'>
          <h3 className='section-title'>
            {i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientDetails')}
          </h3>
          {isCrypto ? (
            renderDetailRow(
              i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientWalletAddress'),
              transaction.transactionData?.recipient?.walletAddress
            )
          ) : (
            <>
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.accountNumber'),
                transaction.transactionData.transaction_recipient.requisites.account_number
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.sortCode'),
                transaction.transactionData.transaction_recipient.requisites.sort_code
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientIban'),
                transaction.transactionData.transaction_recipient.requisites.iban
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.bankName'),
                transaction.transactionData.transaction_recipient.requisites.bank_name
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.bankCountry'),
                transaction.transactionData.transaction_recipient.requisites.bank_country
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientBicSwift'),
                transaction.transactionData.transaction_recipient.requisites.bic
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientName'),
                transaction.counterpartyName
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.registrationNumber'),
                transaction.transactionData.transaction_recipient.requisites.registration_number
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientCity'),
                transaction.transactionData.transaction_recipient.requisites.city
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientCountry'),
                transaction.transactionData.transaction_recipient.requisites.country
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientPostalCode'),
                transaction.transactionData.transaction_recipient.requisites.postal_code
              )}
              {renderDetailRow(
                i18n.getMessage('unapprovedPayments.paymentConfirmation.recipientAddress'),
                transaction.transactionData.transaction_recipient.requisites.address
              )}
            </>
          )}
        </div>
      </div>

      <div className='payment-actions'>
        {/*<Button type='outline' onClick={handleEdit}>*/}
        {/*  {i18n.getMessage('transferConfirmation.button.editTransfer')}*/}
        {/*</Button>*/}
        <Button type='primary' onClick={handleConfirm} isLoading={isLoading}>
          {i18n.getMessage('unapprovedPayments.paymentConfirmation.button.confirm')}
        </Button>
      </div>
    </div>
  );
};

PaymentConfirmation.propTypes = {
  transaction: PropTypes.shape({
    id: PropTypes.string,
    accountNumber: PropTypes.string,
    walletNumber: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    commission: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf([null])]),
    currency: PropTypes.string,
    paymentMethod: PropTypes.string,
    transferProvider: PropTypes.string,
    senderName: PropTypes.string,
    senderAccount: PropTypes.string,
    counterpartyName: PropTypes.string,
    comment: PropTypes.string,
    transactionData: PropTypes.object,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string
  }),
  onEdit: PropTypes.func,
  onConfirm: PropTypes.func,
  setSelectedTransaction: PropTypes.func,
  isLoading: PropTypes.bool,
  customerNumber: PropTypes.string,
  paymentStore: MobXPropTypes.observableObject
};

export default inject((stores) => ({
  paymentStore: stores.paymentStore
}))(observer(PaymentConfirmation));
