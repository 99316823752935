import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { CONFIRMATION_HANDLE_TYPES, SMS_ERROR_CODE_DISABLE } from 'components/common/constants';
import { ConfirmCodeWrapper } from 'components/common/ConfirmCodeWrapper/ConfirmCodeWrapper';
import i18nContext from 'components/i18n-context';
import { isFullSecurityCode } from 'components/utils';
import { getSecureUserPhone } from 'services/authUtils';
import { getErrorMessageForAlert } from 'services/utils';
import Button from 'uikit/Button/Button';
import './PopUpScheme.scss';

const PopUpForApprovalSubmitTransactionConfirmation = ({
  title,
  confirmButtonLabel,
  isLoading,
  onClose,
  onConfirm,
  error,
  clearError,
  resendSecurityCode
}) => {
  const i18n = useContext(i18nContext);
  const [alertState, setAlertState] = useState({ type: '', message: '' });
  const [showCountDown, setShowCountDown] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  useEffect(() => {
    if (SMS_ERROR_CODE_DISABLE.includes(error?.code)) {
      setIsDisabled(true);
    }
    if (error) {
      setAlertState({ type: 'warning', message: getErrorMessageForAlert(i18n, error) });
      setIsConfirmDisabled(true);
    } else {
      setAlertState({ type: '', message: '' });
    }
  }, [error]);

  useEffect(() => {
    setShowCountDown(true);

    return () => {
      clearError();
    };
  }, []);

  const handleResendCode = async () => {
    clearError();
    setShowCountDown(true);
    await resendSecurityCode();
    isDisabled && setIsDisabled(false);
    setAlertState({ type: 'success', message: i18n.getMessage('pin.alert.sent') });
  };

  const onTimerEnd = () => {
    setShowCountDown(false);
  };

  const onComplete = (code) => {
    setSecurityCode(code);
    if (!isFullSecurityCode(code)) {
      setIsConfirmDisabled(true);
    } else {
      setIsConfirmDisabled(false);
      setAlertState({ type: '', message: '' });
    }
  };

  const handleConfirm = () => {
    onConfirm(securityCode);
  };

  return (
    <div className={'scheme-wrapper for-approval-submit-transaction-confirmation'}>
      <div className={'scheme-header'}>
        <p className={'title'}>{title}</p>
        <CloseIcon className={'scheme-close-icon'} onClick={onClose} />
      </div>

      <div className={'scheme-code-wrapper'}>
        <ConfirmCodeWrapper
          PhoneComponent={<strong>{getSecureUserPhone()}</strong>}
          isDisabled={isDisabled}
          error={!error?.type && error}
          onComplete={onComplete}
          showCountDown={showCountDown}
          handleResendCode={handleResendCode}
          onTimerEnd={onTimerEnd}
          alertState={alertState}
          confirmationHandleType={CONFIRMATION_HANDLE_TYPES.ON_CHANGE}
        />
      </div>

      <div className={'scheme-footer'}>
        <Button
          className={'confirm-button'}
          type={'primary'}
          size={'large'}
          isLoading={isLoading}
          onClick={handleConfirm}
          isDisabled={isLoading || isConfirmDisabled}
        >
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};

PopUpForApprovalSubmitTransactionConfirmation.propTypes = {
  title: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  clearError: PropTypes.func,
  error: PropTypes.any,
  resendSecurityCode: PropTypes.func
};

export default PopUpForApprovalSubmitTransactionConfirmation;
